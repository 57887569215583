<template>
	<span :style="{background: 'transparent'}">
		<slot>
			{{ content }}
		</slot>
	</span>
</template>
<script>
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
dayjs.extend(duration)

export default {
	data() {
		return {
			content: '00:00:00',
		};
	},
	props: {
		startTime: {
			type: Number,
		},
	},
	watch: {
		startTime: {
      immediate: true,
      handler(){
        if(!this.timer){
          return
        }
        if(this.startTime){
          this.timer(this.startTime);
        }else {
          if(this.t){
            clearInterval(this.t)
          }
        }
      }
    }
	},
	methods: {
		timer(timestamp) {
			let self = this;
			this.t = setInterval(function () {
        if(timestamp){
          let nowTime = dayjs(timestamp)
          let endTime = dayjs()

          const dur = dayjs.duration(endTime.diff(nowTime))
          self.content = dur.format('HH:mm:ss');
        }
			}, 1000);
		},
	},
};
</script>
